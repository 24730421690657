module.exports = {
  // domain: "http://localhost:8581",
  domain: "https://api.ayabrackett.com",
  // domain: "https://admin.ayabrackett.com",
  // domain: "https://test-admin.ayabrackett.com",

  categories: [
    "Still Life",
    "Portraits",
    "Travel",
    "Magazines",
    "Books",
    "Advertising",
  ],
};
